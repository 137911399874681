<template>
  <div>
    <b-row class="mt-3 mt-lg-4">
      <b-col v-for="(el, i) in list" :key="i" cols="12" sm="6" xl="4">
        <div class="card c-team-list-card overflow-hidden mb-3 mb-lg-4">
          <div
            :style="{ backgroundImage: `url('${el.thumb}')` }"
            class="c-team-list-img"
          />
          <div class="card-body c-team-list-body">
            <h4 class="card-title">{{ el.title }}</h4>
            <h6 class="card-subtitle text-muted mb-2">{{ el.c_title }}</h6>
            <div v-html="el.content" class="d-block d-sm-none" />
            <b-icon-arrow-right-short />
          </div>
          <button @click="onclick(i)" />
        </div>
      </b-col>
    </b-row>
    <b-modal id="j-team-list-modal" size="xl" centered>
      <base-horizontal-card
        :img="activeItem.thumb"
        :title="activeItem.title"
        :sub-title="activeItem.c_title"
        :detail="activeItem.content"
        border-variant="light"
        class="c-team-list-detail"
      />
      <template #modal-footer>
        <b-button-group size="sm">
          <b-button variant="outline-secondary" @click="onPrev">
            <b-icon icon="chevron-left" />
          </b-button>
          <b-button variant="outline-secondary" @click="onNext">
            <b-icon icon="chevron-right" />
          </b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BaseHorizontalCard from "@/components/BaseHorizontalCard";

export default {
  name: "TeamList",
  components: { BaseHorizontalCard },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  computed: {
    activeItem() {
      return this.list[this.activeIndex] || {};
    },
  },
  methods: {
    onclick(i) {
      this.activeIndex = i;
      this.$bvModal.show("j-team-list-modal");
    },
    onPrev() {
      this.activeIndex =
        this.activeIndex === 0 ? this.list.length - 1 : this.activeIndex - 1;
    },
    onNext() {
      this.activeIndex =
        this.activeIndex === this.list.length - 1 ? 0 : this.activeIndex + 1;
    },
  },
};
</script>

<style lang="scss">
.c-team-list {
  &-card {
    position: relative;
    box-shadow: 5px 5px #f2f2f2;

    button {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      box-shadow: none;
      cursor: pointer;

      button {
        z-index: 9;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        background-color: transparent;
      }

      &:hover {
        .c-team-list-body {
          &::after {
            width: 100%;
          }

          .card-title {
            font-size: 2em;
            color: $white;
            transform: translateY(10px);
          }

          .card-subtitle {
            transform: translateY(100px);
            // color: $white !important;
          }

          .b-icon {
            transform: translateX(20px);
          }
        }
      }
    }
  }

  &-img {
    height: 450px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    @include media-breakpoint-up(md) {
      height: 550px;
    }

    @include media-breakpoint-up(xl) {
      height: 600px;
    }
  }

  &-body {
    position: relative;
    background-color: $white;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: $primary;
      transition: width 0.3s;
    }

    .card-title,
    .card-subtitle {
      position: relative;
      z-index: 1;
    }

    .card-title {
      transition: font-size 0.3s, transform 0.3s;
    }

    .card-subtitle {
      transition: transform 0.3s;
    }

    .b-icon {
      z-index: 1;
      position: absolute;
      top: 30px;
      right: 60px;
      font-size: 36px;
      color: $white;
      transition: transform 0.3s 0.15s;
    }

    @include media-breakpoint-up(sm) {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 96px;
      overflow: hidden;
    }
  }

  &-detail {
    .c-horizontal-card-img {
      height: 350px;
      background-color: #080808;
      text-align: center;

      img {
        width: auto;
        height: 100%;
      }

      @include media-breakpoint-up(lg) {
        height: 550px;
      }
    }
  }
}
</style>
