<template>
  <layout-sub name="core">
    <base-horizontal-card
      :img="person.thumb"
      :title="person.title"
      :sub-title="person.c_title"
      :detail="person.content"
      class="p-core-main"
    />
    <team-list :list="list" />
  </layout-sub>
</template>

<script>
import LayoutSub from "@/components/LayoutSub";
import BaseHorizontalCard from "@/components/BaseHorizontalCard";
import { fatchCurrent } from "@/api";

import TeamList from "./components/TeamList";

export default {
  name: "Core",
  components: { LayoutSub, BaseHorizontalCard, TeamList },
  data() {
    return {
      list: [],
      person: {},
    };
  },
  mounted() {
    // 获取当前页面内容
    this.init();
  },
  methods: {
    async init() {
      const { data } = await fatchCurrent(32);
      if (data) {
        this.person = data[0];
        this.list = data.slice(1);
      }
    },
  },
};
</script>

<style lang="scss">
.p-core {
  &-main {
    box-shadow: 5px 5px #f2f2f2;

    .c-horizontal-card-img {
      height: 500px;
      background-color: #080808;
      text-align: center;

      img {
        width: auto;
        height: 100%;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &-main {
      box-shadow: none;
    }
  }

  @include media-breakpoint-up(lg) {
    &-main {
      .c-horizontal-card-img {
        height: 660px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &-main {
      font-size: $font-size-lg;

      .c-horizontal-card-img {
        height: 720px;
      }
    }
  }
}
</style>
